import { getSlugFromUrl, useFetchLook } from '@seaters-app/data-access';
import { Card, Col, Divider, Row } from 'antd';

import { LoginTypeEnum } from '@seaters-app/constants';

import { useGetAuthOption } from './hooks/useGetAuthOption';
import { ClassicLogin, PimAuth, SamlLogin } from './components';
import './styles.css';

function SignIn() {
  const slug = getSlugFromUrl();

  const { data: look } = useFetchLook(slug || '');

  const pimOption = useGetAuthOption(LoginTypeEnum.PIM).login;
  const classicOption = useGetAuthOption(LoginTypeEnum.CLASSIC).login;
  const samlOption = useGetAuthOption(LoginTypeEnum.SAML).login;

  if (look?.login.length === 1 && samlOption) {
    return <SamlLogin {...samlOption} />;
  }

  return look && slug ? (
    <Card className="publicForm" bordered={false}>
      <Row justify="center" align="middle">
        <Col span={24}>
          {classicOption && <ClassicLogin />}
          {look?.login.length > 0 && pimOption ? <Divider /> : null}
          {pimOption && <PimAuth pimOption={pimOption} />}
        </Col>
      </Row>
    </Card>
  ) : (
    <Card className="publicForm" bordered={false}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <ClassicLogin />
        </Col>
      </Row>
    </Card>
  );
}

export default SignIn;

// portofantwerpbusiness – SAML
// portofantwerpemployees – OAUTH
